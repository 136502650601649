<template>
  <div class="book-body">
    <div class="book" v-for="item in bookItems" :key="item.title">
      <img class="book__img" :src="item.url" :alt="item.title" />
      <div class="book__info">
        <h3 class="title-book">{{ item.title }}</h3>
        <ul>
          <li>
            Стоимость:<span> {{ item.sale }} </span>
            <i class="fas fa-ruble-sign"></i>
          </li>
          <li>
            <i class="far fa-file-alt"></i> <span> {{ item.pages }} </span> с.
          </li>
          <li v-if="item.nal">
            <i class="far fa-check-circle"></i> есть в наличии
          </li>
          <li :class="!item.nal ? 'none' : ''" v-else>
            <i class="far fa-times-circle"></i>нет в наличии
          </li>
          <li v-if="item.dopUrl">
            <span class="text">возможно прослушать оригинал</span>
            <a class="subLink" :href="item.href"> по ссылке</a>
          </li>
        </ul>
        <p class="book__descr">
          {{ item.desc }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bookItems: [
        {
          url: "https://na-russia.org/images/bt.png",
          dopUrl: true,
          href: "http://m.na.org/?ID=bt-aud",
          title: "Анонимые наркоманы Базовый текст (6-е издание)",
          sale: 500,
          pages: 100,
          desc: `Первая книга АН была опубликована в 1983 году, а в 2009 году она была пересмотрена, чтобы включить новые личные истории в шестое издание. «Наша программа» состоит из десяти глав, в которых объясняется Содружество АН и наша программа выздоровления. «Рассказ наших членов» включает в себя личные истории членов АН со всего мира. Есть возможность прослушать бащовый текст по ссылке`,
          nal: true,
        },
        {
          url: "https://cdn1.ozone.ru/multimedia/1005973424.jpg",
          dopUrl: false,
          title: "Только сегодня",
          sale: 310,
          pages: 100,
          desc: `Впервые опубликованная в августе 1992 года, книга «Только сегодня» предлагает тебе один из принципов выздоровления на каждый день года — тему, цитату из литературы АН, размышления на эту тему, а также заключительное утверждение для медитации. Включает в себя предметный указатель.`,
          nal: true,
        },
        {
          url: "https://na-russia.org/images/swg.png",
          dopUrl: false,
          title: "Руководство к работе по шагам в АН",
          sale: 350,
          pages: 194,
          desc: `Эверсия учебного пособия АН «Двенадцать шагов». В нем содержится полезный справочный раздел, в котором обсуждаются принципы, относящиеся к каждому из наших Двенадцати Шагов, а также некоторые практические вопросы для анализа, касающиеся понимания каждым из этих шагов.`,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/iwhw.png",
          dopUrl: false,
          title: "Это работает как и почему",
          sale: 240,
          pages: 153,
          desc: `Причины, по которым наша программа работает, как и почему происходит выздоровление, кроются в коллективной мудрости наших членов, представленных здесь в 24 эссе о Шагах и Традициях АН.
                    Члену: Эта книга представляет собой обсуждение Двенадцати Шагов и Традиций АН, призванное помочь вам определить вашу собственную интерпретацию содержащихся в них принципов. Мы надеемся, что «эта книга действительно представляет терапевтическую ценность помощи одного зависимого другому».`,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/sponsorship.png",
          dopUrl: false,
          title: "Спонсорство",
          sale: 480,
          pages: 244,
          desc: `Эта книга о спонсорстве в АН, проиллюстрированная личным опытом членов, отмечает богатство различий и разнообразный опыт всемирного Содружества Анонимных Наркоманов.`,
          nal: true,
        },
        {
          url: "https://na-russia.org/images/lc.png",
          dopUrl: false,
          title: "Жить чистым",
          sale: 230,
          pages: 223,
          desc: `Послание АН состоит из трех частей: любой наркоман может перестать употреблять, потерять желание употреблять и найти новый образ жизни. Эта книга посвящена поиску нового образа жизни - практике выздоровления в нашей повседневной жизни, в наших отношениях и в нашем служении другим. Он предназначен для того, чтобы приветствовать новых членов АН и пробудить энтузиазм в тех, кто долгое время работал в АН.`,
          nal: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.book {
  display: flex;
  transition: all 0.7s;
  padding: 12px;
  &-body {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0;
    //width: calc(100% - 40px);
  }

  &:hover {
    box-shadow: -1px 0px 10px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  li {
    list-style: none;
    display: flex;
    padding-bottom: 12px;
    align-items: center;
    &:first-of-type {
      span {
        font-size: 24px;
        font-weight: 700;
      }
    }
    span {
      margin: 0 6px;
    }
  }

  i {
    margin-right: 12px;
  }

  &__img {
    margin-right: 24px;
    max-width: 284px;
    min-width: 284px;
    height: 400px;
    flex-basis: 40%;
    @media screen and (max-width: 600px) {
      width: 100%;
      //margin: auto;
    }

    @media (max-width: 320px) {
      max-width: 100%;
      min-width: 100%;
      height: auto;
    }
  }
  &__descr {
    color: #000;
    font-size: 20px;

    @media screen and(max-width: 600px) {
      font-size: 16px;
    }
  }
}

.title-book {
  color: #000;
  margin: 0;
  font-size: 30px;

  @media screen and (max-width: 600px) {
    font-size: 22px;
    margin-top: 6px;
  }
}

.subLink {
  padding-left: 6px;
  text-decoration: none;
  color: #1c7fe2;
  &:hover {
    text-decoration: underline;
  }

  @media screen and (max-width: 600px) {
    white-space: nowrap;
  }
}

.text {
  color: #000;
}

.none {
  color: red;
  font-weight: 700;
}
</style>