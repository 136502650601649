<template>
  <div class="ip-an" ref="ip">
    <div class="ip-an__body">
      <div class="ip-an__block" v-for="item in ipItems" :key="item.title">
        <img class="ip-an__img" :src="item.url" :alt="item.title" />
        <div class="ip-an__text">
          <!-- <h3 class="ip-an__text-title">{{ item.title }}</h3> -->
          <div class="ip-an__footer">
            <div class="ip-an__text-sale">
              <span>{{ item.sale }} </span>
              {{ normalizeCountForm(item.sale, textRub) }}
            </div>
            <span class="ip-an__text-nal" v-if="item.nal">есть в наличии</span>
            <span class="ip-an__text-nal ip-an__text-nal--none" v-else
              >нет в наличии</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay, Zoom } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Zoom]);

export default {
  data() {
    return {
      ipItems: [
        {
          url: "https://rko-na.ru/wp-content/uploads/ip01.png",
          title: "Кто, что, как и почему",
          sale: 5,
          nal: true,
        },
        {
          url: "https://na-russia.org/images/ip02.png",
          title: "Группа",
          sale: 9,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip05.png",
          title: "Другой взгляд",
          sale: 3,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip06.png",
          title: "Выздоровление и срыв",
          sale: 10,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip07.png",
          title: "Зависимый ли я?",
          sale: 5,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip08.png",
          title: "Только сегодня",
          sale: 3,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip09.png",
          title: "Жить программой",
          sale: 8,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip11.png",
          title: "Cпонсорство",
          sale: 5,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip12.png",
          title: "Треугольник одержимости своими желаниями",
          sale: 3,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip13.jpg",
          title: "Юным зависимым от юных зависимых",
          sale: 12,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip14.png",
          title: "Опыт одного зависимого",
          sale: 3,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip15.png",
          title: "Служение СО и членсво АН",
          sale: 3,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip16.png",
          title: "Новичку",
          sale: 3,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip17.png",
          title: "Если ты на реабилитации",
          sale: 12,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip19.png",
          title: "Принятие себя",
          sale: 3,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip20.png",
          title: "Служение БУ и членство АН",
          sale: 3,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip21.png",
          title: "Одиночка - оставаться чистрым в изоляции",
          sale: 9,
          nal: true,
        },
        {
          url: "https://rko-na.ru/wp-content/uploads/ip22.png",
          title: "Добро пожаловать в ан",
          sale: 3,
          nal: true,
        },
        {
          url: "https://na-russia.org/images/ip23.png",
          title: "Оставаться чистым после выписки",
          sale: 5,
          nal: true,
        },
        {
          url: "https://na-russia.org/images/ip24.png",
          title: "Про деньги. Самообеспечение имеет значение",
          sale: 11,
          nal: true,
        },
        {
          url: "https://na-russia.org/images/ip26.jpg",
          title:
            "Доступность собраний для зависимых с ограниченными возможностями",
          sale: 4,
          nal: true,
        },
        {
          url: "https://na-russia.org/images/ip27.jpg",
          title: "Для родителей или опекунов несовершеннолетних в АН ",
          sale: 6,
          nal: true,
        },
        {
          url: "https://na-russia.org/images/ip28.png",
          title: "Финансирование органов обслуживания АН",
          sale: 10,
          nal: true,
        },
        {
          url: "https://na-russia.org/images/ip29.png",
          title: "Собрания сообщества АН: что это такое?",
          sale: 12,
          nal: true,
        },
      ],
      isMobile: window.matchMedia("(max-width: 800px)").matches,
      textRub: ["рубль", "рубля", "рублей"],
    };
  },

  methods: {
    normalizeCountForm(number, words_arr) {
      number = Math.abs(number);
      if (Number.isInteger(number)) {
        let options = [2, 0, 1, 1, 1, 2];
        return words_arr[
          number % 100 > 4 && number % 100 < 20
            ? 2
            : options[number % 10 < 5 ? number % 10 : 5]
        ];
      }
      return words_arr[1];
    },
  },

  mounted() {
    if (this.isMobile) {
      setTimeout(() => {
        new Swiper(this.$refs.ip, {
          wrapperClass: "ip-an__body",
          slideClass: "ip-an__block",
          slidesPerView: "auto",
          autoHeight: false,
          zoom: false,
          spaceBetween: 20,
          // navigation: {
          //   prevEl: this.$refs.prev,
          //   nextEl: this.$refs.next,
          // },
          // pagination: {
          //   el: ".slider__pagination",
          // },
          speed: 500,

          // breakpoints: {
          //   320: {
          //     spaceBetween: 0,
          //   },
          //   480: {
          //     spaceBetween: 20,
          //   },
          // },
        });
      }, 300);
    }
  },
};
</script>

<style lang="scss">
$main-color-blue: #1c7fe2;
.ip-an {
  margin: 0 0 40px;
  @media (max-width: 800px) {
    overflow: hidden;
    width: 100vw;
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 80px 20px;
    width: 100%;
    min-width: 0;

    @media (max-width: 800px) {
      display: flex;
      gap: 0;
    }
  }

  &__block {
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 600px;

    @media (max-width: 800px) {
      width: 100%;
      max-width: 290px;
      height: 500px;
    }

    @media (max-width: 320px) {
      max-width: 140px;
    }
  }

  &__img {
    box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.2);
    // margin-bottom: 12px;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    @media (max-width: 800px) {
      box-shadow: none;
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 12px;
    align-items: center;
    border: solid 2px #000;
    border-radius: 0.5em;
    color: #000;
    padding: 0 12px 0 0;
    width: 100%;

    @media (max-width: 850px) {
      grid-template-columns: 290px;
      padding: 0;
    }
  }

  &__text {
    display: flex;
    margin-top: auto;
    position: relative;

    background: #fff;
    z-index: 1;
    border-radius: 0.5em;

    &-title {
      margin: 0;
      margin-bottom: 12px;
      font-size: 26px;
      color: #000;
      text-align: center;
    }

    &-sale,
    &-nal {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      transition: all 0.5s;
      width: 100%;

      @media screen and (max-width: 600px) {
        margin-bottom: 6px;
      }
    }
    &-sale {
      display: grid;
      grid-auto-flow: column;
      column-gap: 0.5rem;
      align-items: center;
      position: relative;

      &::after {
        content: "/";
        position: absolute;
        top: 5px;
        right: 0;
        font-weight: 700;

        @media (max-width: 850px) {
          content: none;
        }
      }

      span {
        font-size: 24px;
        font-weight: 700;
      }
    }
    &-nal {
      &--none {
        color: red;
        font-weight: 700;
      }
    }
  }
}
</style>