<template>
  <div class="bk content">
    <div class="bk-body" v-for="i of bookLItems" :key="i.url">
      <img class="bk-body__pict" :src="i.url" :alt="i.title" />
      <div class="bk-body__text">
        <h3 class="bk-body__title">{{ i.title }}</h3>
        <div class="bk-body__footer">
          <div class="bk-body__sale">
            <span>{{ i.sale }}</span> {{ normalizeCountForm(i.sale, textRub) }}
          </div>
          <span class="bk-body__nal" v-if="i.nal">Есть в наличии</span>
          <span class="bk-body__nal bk-body__nal--none" v-else
            >Нет в наличии</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bookLItems: [
        {
          url: require("@/assets/Literatura/white.png"),
          title: "Белый буклет",
          sale: 12,
          nal: true,
        },
        {
          url: require("@/assets/Literatura/group.png"),
          title: "Буклет для группы",
          sale: 70,
          nal: true,
        },
        {
          url: require("@/assets/Literatura/12Concepts.jpg"),
          title: "Двенадцать Концепций Обслуживания АН",
          sale: 110,
          nal: true,
        },
        {
          url: require("@/assets/Literatura/step4.png"),
          title: "Работа над Шагом Четвертым в Анонимных Наркоманах",
          sale: 26,
          nal: true,
        },
        {
          url: require("@/assets/Literatura/btw.png"),
          title: "В неволе",
          sale: 40,
          nal: true,
        },
        {
          url: require("@/assets/imgPriabuls/Заболевание в выздоровлении.png"),
          title: "Заболевания в выздоровлении",
          sale: 60,
          nal: true,
        },
        {
          url: require("@/assets/Literatura/priambuls.jpg"),
          title: "Карточки для чения на группах",
          sale: 174,
          nal: true,
        },
      ],
      textRub: ["рубль", "рубля", "рублей"],
    };
  },
  methods: {
    normalizeCountForm(number, words_arr) {
      number = Math.abs(number);
      if (Number.isInteger(number)) {
        let options = [2, 0, 1, 1, 1, 2];
        return words_arr[
          number % 100 > 4 && number % 100 < 20
            ? 2
            : options[number % 10 < 5 ? number % 10 : 5]
        ];
      }
      return words_arr[1];
    },
  },
  mounted() {
    // console.log('my slides ->', this.newImg1);
  },
};
</script>

<style lang="scss">
.bk {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-auto-rows: minmax(450px, 1fr);
  gap: 80px 20px;
  justify-content: space-between;
  color: #000;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(500px, 1fr);
    row-gap: 40px;
    width: calc(100% - 40px);
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s;
    position: relative;
    // height: 450px;

    @media (max-width: 800px) {
      // height: 500px;
    }

    &__pict {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      inset: 0;
      // object-fit: cover;
    }

    @media (max-width: 320px) {
      margin: 0 4px 12px;
    }

    &__text {
      z-index: 2;
      position: relative;
      margin-top: auto;
      background: #fff;
      border-radius: 0.5em;
    }

    &__title {
      color: #000;
      margin: 0;
      font-size: 26px;
      text-align: center;
      margin-bottom: 12px;
      display: none;

      @media screen and (max-width: 600px) {
        font-size: 22px;
        margin-top: 6px;
      }
    }

    &__footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 12px;
      align-items: center;
      border: solid 2px #000;
      border-radius: 0.5em;
    }

    &__sale,
    &__nal {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      transition: all 0.5s;

      @media screen and (max-width: 600px) {
        margin-bottom: 6px;
      }
    }

    &__sale {
      display: grid;
      grid-auto-flow: column;
      column-gap: 0.5rem;
      align-items: center;
      position: relative;

      &::after {
        content: "/";
        position: absolute;
        top: 5px;
        right: 0;
        font-weight: 700;
      }

      span {
        font-size: 24px;
        font-weight: 700;
      }
    }
    &__nal {
      &--none {
        color: red;
        font-weight: 700;
      }
    }
  }
}
</style>