<template>
  <div class="literature">
    <h1 class="header-title">Литература AN</h1>
    <h2 class="header-title_sub">Книги</h2>
    <book />
    <h2 class="header-title_sub">Буклеты</h2>
    <booklets />
    <h2 class="header-title_sub header-title_mob">
      ИП(Информационные проспекты)
    </h2>
    <ipliter />
  </div>
</template>

<script>
import Book from "../components/Literature/Book";
import Ipliter from "../components/Literature/Ipliter";
import Booklets from "../components/Literature/Booklets";

export default {
  created() {
    document.title = "Литература";
  },
  components: {
    Book,
    Ipliter,
    Booklets,
  },
};
</script>

<style lang="scss">
.literature {
  ul {
    padding: 0;
  }

  @media (max-width: 850px) {
    overflow: hidden;
  }
}
</style>